import {
  message,
  PageHeader,
  Spin,
  Select,
  Form as FormInitial,
  Row,
  Col,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  SelectItem,
  InputNumberItem,
  dineroDisabledMask,
  round2Decimals,
  ButtonItem,
  round6Decimals,
  HeaderItem,
  BoxItem,
  ErrorItem,
  DatePickerItem,
} from "../../../../Components/Items";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import ElegirTipoDeProveedor from "../../Proveedores/ElegirTipoDeProveedor/ElegirTipoDeProveedor";
import MandarCorreoOrdenDeCompra from "../MandarCorreoOrdenDeCompra/MandarCorreoOrdenDeCompra";

const { Option } = Select;

class AgregarOrdenDeCompra extends Component {
  formRef = React.createRef();
  state = {
    modalCorreoId: null,
    modalCorreo: false,
    loading: false,
    objetoProveedores: null,
    todasProveedores: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoInsumos: (
      <Option disabled value={0} key={0}>
      Seleccione un proveedor primero
    </Option>
    ),
    todasInsumos: [],
    impuestos: [],
    modalElegirTipoDeProveedor: false,
    modalElegirTipoDeProveedorId: null,
    modalElegirTipoDeProveedorNombre: null,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  abrirModalCorreo(ordenId) {
    this.setState({ modalCorreo: true, modalCorreoId: ordenId });

  }
  
  cerrarModalCorreo() {
    this.setState({ modalCorreo: false, modalCorreoId:null});
    this.atras();

  }

  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

  async consultarTodo() {
    this.setState({ loading: true });
    const responseProveedores = axios
    .post(
      "proveedores/selectProveedores",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )

    const responseProyectos = axios
    .post(
      "proyectos/selectProyectos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )

    const responseMonedas = axios
    .post(
      "monedas/selectMonedas",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )

    const responses = await Promise.all([
      responseProveedores,
      responseProyectos,
      responseMonedas
    ])

    let newState = {loading: false}

    if (responses[0].data.status === 404) {
    } else {
      newState.todasProveedores = responses[0].data
      newState.objetoProveedores = responses[0].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.stringBuscar}
          </Option>
        );
      })
    }

    if (responses[1].data.status === 404) {
    } else {
      newState.todasProyectos = responses[1].data
      newState.objetoProyectos = responses[1].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre}
          </Option>
        );
      })
    }

    if (responses[2].data.status === 404) {
    } else {
      newState.todasMonedas = responses[2].data
      newState.objetoMonedas = responses[2].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre + " - " + value.abreviacion}
          </Option>
        );
      })
    }

    await this.promisedSetState(newState)
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    let insumos = values.insumos;
    let total = 0;
    let subtotal = 0;
    let arrayImpuestos = [];
    let arrayRetenciones = [];
    let cuentasProveedor = 0;
    insumos.map((value) => {
      total += value.subtotal;
      subtotal += value.subtotal;
      value.arrayImpuestos.map((valueImpuestos) => {
        let index = arrayImpuestos.findIndex(
          (valueImpuestosAnterios) =>
            valueImpuestosAnterios.descripcionFactura ===
            valueImpuestos.descripcionFactura
        );
        if (index !== -1) {
          let newItem = {
            totalImpuestos:
              arrayImpuestos[index].totalImpuestos +
              valueImpuestos.totalImpuestos,
            descripcionFactura: valueImpuestos.descripcionFactura,
          };
          arrayImpuestos.splice(index, 1, newItem);
        } else {
          arrayImpuestos.push({
            totalImpuestos: valueImpuestos.totalImpuestos,
            descripcionFactura: valueImpuestos.descripcionFactura,
          });
        }
        return null;
      });
      if(value.arrayRetenciones){
      value.arrayRetenciones.map((valueRetenciones) => {
        let index = arrayRetenciones.findIndex(
          (valueRetencionesAnterios) =>
            valueRetencionesAnterios.descripcionFactura ===
            valueRetenciones.descripcionFactura
        );
        cuentasProveedor += round2Decimals(valueRetenciones.totalRetenciones);
        if (index !== -1) {
          let newItem = {
            totalRetenciones:
              arrayRetenciones[index].totalRetenciones +
              valueRetenciones.totalRetenciones,
            descripcionFactura:
              valueRetenciones.descripcionFactura,
          };
          arrayRetenciones.splice(index, 1, newItem);
        } else {
          arrayRetenciones.push({
            totalRetenciones: valueRetenciones.totalRetenciones,
            descripcionFactura:
              valueRetenciones.descripcionFactura,
          });
        }
        return null;
      });
    }
      return null;
    });
    arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
      total += round2Decimals(valueImpuesto.totalImpuestos)
      return {
        ...valueImpuesto,
        totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos),
      }
    })
    axios
      .post(
        "ordenesdecompra/agregarOrdenDeCompra",
        {
          ...values,
          empresaId: this.props.empresaId,
          total,
          subtotal,
          cuentasProveedor,
          totalPagar: total - cuentasProveedor,
          arrayImpuestos,
          arrayRetenciones
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se ha registrado una orden de compra con codigo: " +
              res.data.codigo
              
          );
          this.abrirModalCorreo(res.data.ordenId);

          this.formRef.current.resetFields();
        } else if (res.data.success === 1) {
          message.error("No se puede registrar la orden de compra");
        } else {
          message.error("Hubo un error y la orden de compra no se registro");
        }
      }
    );
  }

  async onChangeInsumo(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proveedorId = await this.formRef.current.getFieldValue("proveedorId");
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === value
    );
    if(!insumo) return
    arrayValue[key].precioUnitario = insumo.costo;

    const precioUnitario = arrayValue[key].precioUnitario

    let totalImpuestosPrecioSinImpuestos = 0
    insumo.impuestos.map(valueImpuesto => {
      let totalImpuesto
      if(valueImpuesto.tasaCuota === 1){
        totalImpuesto = (precioUnitario*(valueImpuesto.monto/100))
      }else if(valueImpuesto.tasaCuota === 2){
        totalImpuesto = (precioUnitario+totalImpuestosPrecioSinImpuestos)*(valueImpuesto.monto/100)
      }else if(valueImpuesto.tasaCuota === 3){
        totalImpuesto	= valueImpuesto.monto
      }else if(valueImpuesto.tasaCuota === 4){
        let cantidad = arrayValue[key].cantidad
        if(cantidad){
          totalImpuesto = (arrayValue[key].impuestos || 0)/arrayValue[key].cantidad
        }
      }else if(valueImpuesto.tasaCuota === 5){
        totalImpuesto = 0
      }
      totalImpuestosPrecioSinImpuestos += totalImpuesto
      return null
    })
    arrayValue[key].precioUnitarioConImpuestos = round2Decimals(precioUnitario + totalImpuestosPrecioSinImpuestos)

    const cantidad = arrayValue[key].cantidad
    const descuento = arrayValue[key].descuento || 0

    if (precioUnitario && cantidad && insumo) {
      //1
      const subtotal = round2Decimals(cantidad * precioUnitario);
      //2
      const totalDescuento = round2Decimals(subtotal * (descuento/100))
      //3
      const totalEntrada = round2Decimals(subtotal - totalDescuento)
      arrayValue[key].subtotal = totalEntrada;

      let totalImpuestos = 0;
      let arrayImpuestos = insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let totalImpuesto;
          if (valueImpuesto.tasaCuota === 1) {
            totalImpuesto = round6Decimals(totalEntrada * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 2) {
            totalImpuesto =
            round6Decimals((totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 3) {
            totalImpuesto = round6Decimals(cantidad * valueImpuesto.monto);
          }else if(valueImpuesto.tasaCuota === 4){
            totalImpuesto = round6Decimals(arrayValue[key].impuestos)
          }else if(valueImpuesto.tasaCuota === 5){
            totalImpuesto = 0
          }
          totalImpuestos += totalImpuesto;
          return {
            descripcionFactura: valueImpuesto.descripcionFactura,
            totalImpuestos: totalImpuesto,
          };
        }
      );
      arrayValue[key].impuestos = totalImpuestos;
      arrayValue[key].arrayImpuestos = arrayImpuestos;
      //5
      const total = round2Decimals(arrayValue[key].subtotal + totalImpuestos)
      arrayValue[key].total = total;

      if(proveedorId){
        //Consultar proveedor
  
        const proveedor = this.state.todasProveedores.find(
          (valueP) => valueP._id === proveedorId
          );
        //6
        let totalRetenciones = 0;
        let arrayRetenciones = []


        proveedor.retenciones.map((valueRetencion) => {
          //7
            let totalRetencion;
            if (valueRetencion.tasaCuota === 1) {
              totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
            } else if (valueRetencion.tasaCuota === 2) {
              totalRetencion =
              round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
            }
            totalRetenciones += totalRetencion;
            arrayRetenciones.push({
              descripcionFactura: valueRetencion.descripcionFactura,
              totalRetenciones: totalRetencion,
            });
          return null
    })

        insumo.retenciones.map((valueRetencion) => {
          if((valueRetencion.aplicaCompra === 1 && proveedor.persona === false) || (valueRetencion.aplicaCompra === 2 && proveedor.persona === true) || valueRetencion.aplicaCompra === 3){
              //7
                let totalRetencion;
                if (valueRetencion.tasaCuota === 1) {
                  totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                } else if (valueRetencion.tasaCuota === 2) {
                  totalRetencion =
                  round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                }
                totalRetenciones += totalRetencion;
                arrayRetenciones.push({
                  descripcionFactura: valueRetencion.descripcionFactura,
                  totalRetenciones: totalRetencion,
                });
              }
              return null
        })
        arrayValue[key].retenciones = totalRetenciones;
        arrayValue[key].arrayRetenciones = arrayRetenciones;
      }
    }

      this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  async onChangePrecioCantidadDescuento(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proveedorId = await this.formRef.current.getFieldValue("proveedorId");
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === arrayValue[key]?.insumoId
    );
    if(!insumo) return
    const precioUnitario = arrayValue[key].precioUnitario

    let totalImpuestosPrecioSinImpuestos = 0
    insumo.impuestos.map(valueImpuesto => {
      let totalImpuesto
      if(valueImpuesto.tasaCuota === 1){
        totalImpuesto = (precioUnitario*(valueImpuesto.monto/100))
      }else if(valueImpuesto.tasaCuota === 2){
        totalImpuesto = (precioUnitario+totalImpuestosPrecioSinImpuestos)*(valueImpuesto.monto/100)
      }else if(valueImpuesto.tasaCuota === 3){
        totalImpuesto	= valueImpuesto.monto
      }else if(valueImpuesto.tasaCuota === 4){
        let cantidad = arrayValue[key].cantidad
        if(cantidad){
          totalImpuesto = (arrayValue[key].impuestos || 0)/arrayValue[key].cantidad
        }
      }else if(valueImpuesto.tasaCuota === 5){
        totalImpuesto = 0
      }
      totalImpuestosPrecioSinImpuestos += totalImpuesto
      return null
    })
    arrayValue[key].precioUnitarioConImpuestos = round2Decimals(precioUnitario + totalImpuestosPrecioSinImpuestos)
    const cantidad = arrayValue[key].cantidad
    const descuento = arrayValue[key].descuento || 0

    if (precioUnitario && cantidad && insumo) {
      //1
      const subtotal = round2Decimals(cantidad * precioUnitario);
      //2
      const totalDescuento = round2Decimals(subtotal * (descuento/100))
      //3
      const totalEntrada = round2Decimals(subtotal - totalDescuento)
      arrayValue[key].subtotal = totalEntrada;

      let totalImpuestos = 0;
      let arrayImpuestos = insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let totalImpuesto;
          if (valueImpuesto.tasaCuota === 1) {
            totalImpuesto = round6Decimals(totalEntrada * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 2) {
            totalImpuesto =
            round6Decimals((totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 3) {
            totalImpuesto = round6Decimals(cantidad * valueImpuesto.monto);
          }else if(valueImpuesto.tasaCuota === 4){
            totalImpuesto = round6Decimals(arrayValue[key].impuestos)
          }else if(valueImpuesto.tasaCuota === 5){
            totalImpuesto = 0
          }
          totalImpuestos += totalImpuesto;
          return {
            descripcionFactura: valueImpuesto.descripcionFactura,
            totalImpuestos: totalImpuesto,
          };
        }
      );
      arrayValue[key].impuestos = totalImpuestos;
      arrayValue[key].arrayImpuestos = arrayImpuestos;
      //5
      const total = round2Decimals(arrayValue[key].subtotal + totalImpuestos)
      arrayValue[key].total = total;

      if(proveedorId){
        //Consultar proveedor
  
        const proveedor = this.state.todasProveedores.find(
          (valueP) => valueP._id === proveedorId
          );
        //6
        let totalRetenciones = 0;
        let arrayRetenciones = []


        proveedor.retenciones.map((valueRetencion) => {
          //7
            let totalRetencion;
            if (valueRetencion.tasaCuota === 1) {
              totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
            } else if (valueRetencion.tasaCuota === 2) {
              totalRetencion =
              round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
            }
            totalRetenciones += totalRetencion;
            arrayRetenciones.push({
              descripcionFactura: valueRetencion.descripcionFactura,
              totalRetenciones: totalRetencion,
            });
          return null
    })

        insumo.retenciones.map((valueRetencion) => {
          if((valueRetencion.aplicaCompra === 1 && proveedor.persona === false) || (valueRetencion.aplicaCompra === 2 && proveedor.persona === true) || valueRetencion.aplicaCompra === 3){
              //7
                let totalRetencion;
                if (valueRetencion.tasaCuota === 1) {
                  totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                } else if (valueRetencion.tasaCuota === 2) {
                  totalRetencion =
                  round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                }
                totalRetenciones += totalRetencion;
                arrayRetenciones.push({
                  descripcionFactura: valueRetencion.descripcionFactura,
                  totalRetenciones: totalRetencion,
                });
              }
              return null
        })
        arrayValue[key].retenciones = totalRetenciones;
        arrayValue[key].arrayRetenciones = arrayRetenciones;
      }
    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  async onChangePrecioImpuestos(value, key){
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proveedorId = await this.formRef.current.getFieldValue("proveedorId");
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === arrayValue[key].insumoId
    );
    if(!insumo) return

    const precioUnitarioConImpuestos = arrayValue[key].precioUnitarioConImpuestos

    let totalPorcentajeImpuestos = 1
    let totalCantidadImpuestos = 0
    insumo.impuestos.map(valueImpuesto => {
      if(valueImpuesto.tasaCuota === 1){
        totalPorcentajeImpuestos += (valueImpuesto.monto / 100)
      }else if(valueImpuesto.tasaCuota === 2){
        totalPorcentajeImpuestos += totalPorcentajeImpuestos*(valueImpuesto.monto / 100)
      }else if(valueImpuesto.tasaCuota === 3){
        totalCantidadImpuestos	= valueImpuesto.monto
      }
      return null
    })

    
    const precioUnitario = (precioUnitarioConImpuestos/totalPorcentajeImpuestos)+totalCantidadImpuestos
    arrayValue[key].precioUnitario = precioUnitario
    const cantidad = arrayValue[key].cantidad
    const descuento = arrayValue[key].descuento || 0

    if (precioUnitario && cantidad && insumo) {
      //1
      const subtotal = round2Decimals(cantidad * precioUnitario);
      //2
      const totalDescuento = round2Decimals(subtotal * (descuento/100))
      //3
      const totalEntrada = round2Decimals(subtotal - totalDescuento)
      arrayValue[key].subtotal = totalEntrada;

      let totalImpuestos = 0;
      let arrayImpuestos = insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let totalImpuesto;
          if (valueImpuesto.tasaCuota === 1) {
            totalImpuesto = round6Decimals(totalEntrada * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 2) {
            totalImpuesto =
            round6Decimals((totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 3) {
            totalImpuesto = round6Decimals(cantidad * valueImpuesto.monto);
          } else if(valueImpuesto.tasaCuota === 4){
            totalImpuesto = round6Decimals(arrayValue[key].impuestos)
          }else if(valueImpuesto.tasaCuota === 5){
            totalImpuesto = 0
          }
          totalImpuestos += totalImpuesto;
          return {
            descripcionFactura: valueImpuesto.descripcionFactura,
            totalImpuestos: totalImpuesto,
          };
        }
      );
      arrayValue[key].impuestos = totalImpuestos;
      arrayValue[key].arrayImpuestos = arrayImpuestos;
      //5
      const total = round2Decimals(arrayValue[key].subtotal + totalImpuestos)
      arrayValue[key].total = total;

      if(proveedorId){
        //Consultar proveedor
  
        const proveedor = this.state.todasProveedores.find(
          (valueP) => valueP._id === proveedorId
          );
        //6
        let totalRetenciones = 0;
        let arrayRetenciones = []


        proveedor.retenciones.map((valueRetencion) => {
          //7
            let totalRetencion;
            if (valueRetencion.tasaCuota === 1) {
              totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
            } else if (valueRetencion.tasaCuota === 2) {
              totalRetencion =
              round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
            }
            totalRetenciones += totalRetencion;
            arrayRetenciones.push({
              descripcionFactura: valueRetencion.descripcionFactura,
              totalRetenciones: totalRetencion,
            });
          return null
    })

        insumo.retenciones.map((valueRetencion) => {
          if((valueRetencion.aplicaCompra === 1 && proveedor.persona === false) || (valueRetencion.aplicaCompra === 2 && proveedor.persona === true) || valueRetencion.aplicaCompra === 3){
              //7
                let totalRetencion;
                if (valueRetencion.tasaCuota === 1) {
                  totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
                } else if (valueRetencion.tasaCuota === 2) {
                  totalRetencion =
                  round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
                }
                totalRetenciones += totalRetencion;
                arrayRetenciones.push({
                  descripcionFactura: valueRetencion.descripcionFactura,
                  totalRetenciones: totalRetencion,
                });
              }
              return null
        })
        arrayValue[key].retenciones = totalRetenciones;
        arrayValue[key].arrayRetenciones = arrayRetenciones;
      }
    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }

  // async onChangeProveedor(value){
  //   const proveedor = this.state.todasProveedores.find(valueProv => valueProv._id === value)
  //   if(!proveedor.tipoProveedorId){
  //     const nombre = proveedor.persona ? proveedor.razonSocial : `${proveedor.nombre} ${proveedor.apellidoPaterno} ${proveedor.apellidoMaterno}`

  //     this.abrirElegirTipoDeProveedor(proveedor._id, nombre)
  //   }
  //   let arrayValue = await this.formRef.current.getFieldValue("insumos");
  //   if(arrayValue?.length > 0){
  //     arrayValue.map((valueInsumo, key) => this.onChangePrecioCantidadDescuento(undefined, key))
  //   }

  // }

  async onChangeProveedor(value, _, dejarInsumos) {
    const proveedor = this.state.todasProveedores.find(valueProv => valueProv._id === value)
    if (!proveedor.tipoProveedorId) {
      const nombre = proveedor.persona ? proveedor.razonSocial : `${proveedor.nombre} ${proveedor.apellidoPaterno} ${proveedor.apellidoMaterno}`
      this.abrirElegirTipoDeProveedor(proveedor._id, nombre)
    }
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    await this.consultarInsumoProveedor(value)

    if (arrayValue?.length > 0 && dejarInsumos) {
      arrayValue.map((valueInsumo, key) => this.onChangePrecioCantidadDescuento(null, key))
    }
    this.setState({ loading: false })
  }

  async consultarInsumoProveedor(proveedorId) {
    await this.setState({ loading: true })
    await axios
      .post(
        "insumos/selectInsumosRelacionesProveedor",
        { empresaId: this.props.empresaId, proveedorId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          this.setState({
            todasInsumos: [],
            objetoInsumos: (
                <Option disabled value={0} key={0}>
                  No tiene insumos este proveedor
                </Option>
              )
          });
        } else {
          this.setState({
            todasInsumos: res.data,
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
  }

  abrirElegirTipoDeProveedor(id, nombre) {
    this.setState({ modalElegirTipoDeProveedor: true, modalElegirTipoDeProveedorId: id, modalElegirTipoDeProveedorNombre: nombre });
  }
  cerrarElegirTipoDeProveedor(elegido) {
    if(!elegido){
      this.formRef.current.setFieldsValue({proveedorId: undefined})
    }
    this.consultarTodo()
    this.setState({ modalElegirTipoDeProveedor: false, modalElegirTipoDeProveedorId: null, modalElegirTipoDeProveedorNombre: null });
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Orden de Compra"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarOrdenDeCompra"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                   
                >
                  Agregar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={18}>
                  <HeaderItem>DATOS GENERALES</HeaderItem>
                    <BoxItem>
                      <Row>
                        <Col span={12}>
                          <FormItem label="Proyecto" name="proyectoId" margin required>
                            <SelectItem placeholder="Proyecto">
                              {this.state.objetoProyectos}
                            </SelectItem>
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              let proyectoId = getFieldValue("proyectoId");
                              if (!proyectoId)
                                return <p>Seleccione un proyecto primero</p>;
                              let proyecto = this.state.todasProyectos.find(
                                (value) => value._id === proyectoId
                              );
                              return (
                                <>
                                  <Row>
                                    <Col span={12}>
                                      <h1 style={{ margin: 0 }}>
                                        Nombre: {proyecto.nombre}
                                      </h1>
                                      <p>Descripcion: {proyecto.descripcion}</p>
                                    </Col>
                                    <Col span={12}>
                                      <h1 style={{ margin: 0 }}>Dirección de envio</h1>
                                      {proyecto.calle ? (
                                        <>
                                          <p style={{ margin: 0 }}>
                                            Calle: {proyecto.calle}
                                          </p>
                                          <p style={{ margin: 0 }}>
                                            Numero: {proyecto.numeroCalle}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p style={{ margin: 0 }}>
                                            Marca: {proyecto.marca}
                                          </p>
                                          <p style={{ margin: 0 }}>
                                            Modelo: {proyecto.modelo}
                                          </p>
                                          <p style={{ margin: 0 }}>
                                            Placas: {proyecto.placas}
                                          </p>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                </>
                              );
                            }}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <FormItem
                            label="Proveedor"
                            name="proveedorId"
                            margin
                            required
                          >
                            <SelectItem placeholder="Proveedor" onChange={this.onChangeProveedor.bind(this)}>
                              {this.state.objetoProveedores}
                            </SelectItem>
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              let proveedorId = getFieldValue("proveedorId");
                              if (!proveedorId)
                                return <p>Seleccione un proveedor primero</p>;
                              let proveedor = this.state.todasProveedores.find(
                                (value) => value._id === proveedorId
                              );
                              return (
                                <>
                                  <Row>
                                    <Col span={12}>
                                      <h1 style={{ margin: 0 }}>
                                        Nombre:{" "}
                                        {proveedor.nombre
                                          ? proveedor.nombre +
                                            " " +
                                            proveedor.apellidoPaterno +
                                            " " +
                                            proveedor.apellidoMaterno
                                          : proveedor.razonSocial}
                                      </h1>
                                      <p style={{ margin: 0 }}>Rfc: {proveedor.rfc}</p>
                                      <p style={{ margin: 0 }}>
                                        Correo: {proveedor.correo}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        Telefono: {proveedor.telefono}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        Celular: {proveedor.celular}
                                      </p>
                                    </Col>
                                    <Col span={12}>
                                      <p style={{ margin: 0 }}>
                                        Contacto: {proveedor.nombreContacto}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        Tel. Contacto: {proveedor.telefonoContacto}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        Correo Contacto: {proveedor.correoContacto}
                                      </p>
                                      <p style={{ margin: 0 }}>
                            Dias de crédito:{" "}
                            {proveedor.diasCredito}
                          </p>
                                    </Col>
                                  </Row>
                                </>
                              );
                            }}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                      <Col span={12}>
                        <FormItem
                          label="Fecha Entrega"
                          name="fechaEntrega"
                          margin
                        >
                          <DatePickerItem
                            placeholder="Fecha de Entrega"
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    </BoxItem>
                </Col>
                <Col span={6}>
                  <HeaderItem>MONEDA</HeaderItem>
                  <BoxItem>
                    <FormItem
                      noStyle
                      shouldUpdate={(prev, curr) =>
                        prev.insumos?.length !==
                        curr.insumos?.length
                      }
                    >
                      {({ getFieldValue }) => {
                        const insumos = getFieldValue("insumos");
                        const disabled =
                          (insumos && insumos.length > 0
                            ? true
                            : false) || true;

                            //Por el momento

                        return (
                          <FormItem
                            required
                            margin
                            name="monedaId"
                            label="Moneda"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue='60c7e4396465ad440c41c15a'
                          >
                            <SelectItem onClick={() => message.info('Por el momento nomas se pueden realizar ordenes de compra con moneda MXN')} disabled={disabled} onChange={(value) => {
                              if(value === '60c7e4396465ad440c41c15a'){
                                this.formRef.current.setFieldsValue({tipoDeCambio: 1})
                              }
                            }} placeholder="Moneda">
                              {this.state.objetoMonedas}
                            </SelectItem>
                          </FormItem>
                        )
                      }}
                    </FormItem>
                    <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId || prev.insumos?.length !== curr.insumos?.length}>
                      {({getFieldValue}) => {

                        const monedaId = getFieldValue('monedaId')
                        const insumos = getFieldValue("insumos");
                        const disabled =
                          insumos && insumos.length > 0
                            ? true
                            : false;

                        return(
                          <FormItem
                            required
                            number
                            margin
                            label="T. Cambio"
                            name="tipoDeCambio"
                            type="number"
                            initialValue={1}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <InputNumberItem
                              disabled={monedaId === '60c7e4396465ad440c41c15a' || disabled}
                              dinero
                              placeholder="T. Cambio"
                            />
                          </FormItem>
                        )
                      }}
                    </FormItem>
                  </BoxItem>
                </Col>
              </Row>
              <HeaderItem>INSUMOS</HeaderItem>
              <BoxItem>
                <FormInitial.List name="insumos">
                  {(fields, { add, remove }) => (
                    <>
                      <table className="table">
                        {fields.length > 0 ? (
                          <thead>
                            <tr>
                              <th className="th-border" style={{ width: "26%" }}>
                                Insumo
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Precio Unitario
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Precio Unitario con Impuestos
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Cantidad
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Descuento
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Subtotal
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Impuestos
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Total
                              </th>
                              <th
                                  className="th-border-sin-right"
                                  style={{ width: "4%" }}
                              ></th>
                            </tr>
                          </thead>
                        ) : null}
                        {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                          <tbody key={arrayKey}>
                            <tr>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "insumoId"]}
                                  required
                                  noStyle
                                >
                                  <SelectItem
                                    sinBorde
                                    autoFocus
                                    style={{ width: "100%" }}
                                    placeholder="Insumo"
                                    onChange={(value) =>
                                      this.onChangeInsumo(value, arrayKey)
                                    }
                                  >
                                    {this.state.objetoInsumos}
                                  </SelectItem>
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "precioUnitario"]}
                                  noStyle
                                  required
                                >
                                  <InputNumberItem
                                    sinBorde
                                    placeholder="Precio Unitario"
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                    dinero
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                              <FormItem
                                  {...restField}
                                  noStyle
                                  shouldUpdate
                                >
                                  {({getFieldValue}) => {
                                      let arrayValue = getFieldValue("insumos");
                                      let disabled = true
                                      if(arrayValue[arrayKey]?.insumoId){
                                        const insumo = this.state.todasInsumos.find(
                                          (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                        );
                                        disabled = !!insumo?.impuestos[0]?.combustible
                                      }
                                      return(
                                        <FormItem
                                        name={[name, "precioUnitarioConImpuestos"]}
                                        noStyle
                                        required
                                        shouldUpdate
                                      >
                                        <InputNumberItem
                                          sinBorde
                                        disabled={disabled}
                                          placeholder="Precio Unitario con Impuestos"
                                          onChange={(value) =>
                                            this.onChangePrecioImpuestos(value, arrayKey)
                                          }
                                          dinero={!disabled}
                                          dineroDisabled={disabled}
                                        />
                                      </FormItem>
                                      )
                                  }}
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "cantidad"]}
                                  required
                                  number
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    placeholder="Cantidad"
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "descuento"]}
                                  required
                                  numberCero
                                  noStyle
                                  initialValue={0}
                                >
                                  <InputNumberItem
                                    sinBorde
                                    placeholder="Descuento"
                                    porcentaje
                                    max={100}
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "subtotal"]}
                                  required
                                  numberCero
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    disabled
                                    placeholder="Subtotal"
                                    dineroDisabled
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  noStyle
                                  shouldUpdate
                                >
                                  {({getFieldValue}) => {
                                      let arrayValue = getFieldValue("insumos");
                                      let disabled = true
                                      if(arrayValue[arrayKey]?.insumoId){
                                        const insumo = this.state.todasInsumos.find(
                                          (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                        );
                                        disabled = !insumo?.impuestos[0]?.combustible
                                      }
                                      return(
                                        <FormItem
                                        name={[name, "impuestos"]}
                                        required
                                        numberCero
                                        noStyle
                                      >
                                        <InputNumberItem
                                          sinBorde
                                          dinero={!disabled}
                                          dineroDisabled={disabled}
                                          disabled={disabled}
                                          placeholder="Impuestos"
                                          onChange={(value) =>
                                            this.onChangePrecioCantidadDescuento(value, arrayKey)
                                          }
                                        />
                                        </FormItem>
                                      )
                                  }}
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "total"]}
                                  required
                                  numberCero
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    dineroDisabled
                                    disabled
                                    placeholder="Total"
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border-sin-right">
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                      style={{
                                        alignSelf: "center",
                                        justifySelf: "center",
                                      }}
                                    />
                                </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                      <FormItem noStyle>
                          <ButtonItem
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              justifySelf: fields.length > 0 ? "left" : null,
                              width: fields.length > 0 ? "auto" : null,
                            }}
                            type="primary"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar Insumo
                          </ButtonItem>
                        </FormItem>
                    </>
                  )}
                </FormInitial.List>
                <Row>
                  <Col span={16}></Col>
                  <Col span={8}>
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        let arrayInsumos = getFieldValue("insumos") || [];
                        // let proveedorId = getFieldValue("proveedorId");
                        let arrayRetenciones = [];
                        let cuentasProveedor = 0;
                        let subtotal = 0;
                        let arrayImpuestos = [];
                        let total = 0;
                        arrayInsumos.map((value) => {
                          if (value) {
                            if (
                              value.subtotal &&
                              value.total &&
                              value.arrayImpuestos
                            ) {
                              subtotal += value.subtotal;
                              total += value.subtotal;
                              value.arrayImpuestos.map((valueImpuestos) => {
                                let index = arrayImpuestos.findIndex(
                                  (valueImpuestosAnterios) =>
                                    valueImpuestosAnterios.descripcionFactura ===
                                    valueImpuestos.descripcionFactura
                                );
                                if (index !== -1) {
                                  let newItem = {
                                    totalImpuestos:
                                      arrayImpuestos[index].totalImpuestos +
                                      valueImpuestos.totalImpuestos,
                                    descripcionFactura:
                                      valueImpuestos.descripcionFactura,
                                  };
                                  arrayImpuestos.splice(index, 1, newItem);
                                } else {
                                  arrayImpuestos.push({
                                    totalImpuestos: valueImpuestos.totalImpuestos,
                                    descripcionFactura:
                                      valueImpuestos.descripcionFactura,
                                  });
                                }
                                return null;
                              });
                              if(value.arrayRetenciones){
                                value.arrayRetenciones.map((valueRetenciones) => {
                                  let index = arrayRetenciones.findIndex(
                                    (valueRetencionesAnterios) =>
                                      valueRetencionesAnterios.descripcionFactura ===
                                      valueRetenciones.descripcionFactura
                                  );
                                  cuentasProveedor += round2Decimals(valueRetenciones.totalRetenciones);
                                  if (index !== -1) {
                                    let newItem = {
                                      totalRetenciones:
                                        arrayRetenciones[index].totalRetenciones +
                                        valueRetenciones.totalRetenciones,
                                      descripcionFactura:
                                        valueRetenciones.descripcionFactura,
                                    };
                                    arrayRetenciones.splice(index, 1, newItem);
                                  } else {
                                    arrayRetenciones.push({
                                      totalRetenciones: valueRetenciones.totalRetenciones,
                                      descripcionFactura:
                                        valueRetenciones.descripcionFactura,
                                    });
                                  }
                                  return null;
                                });
                              }
                            }
                          }
                          return null;
                        });
                        arrayImpuestos = arrayImpuestos.map(valueImpuesto => {
                          total += round2Decimals(valueImpuesto.totalImpuestos)
                          return {
                            ...valueImpuesto,
                            totalImpuestos: round2Decimals(valueImpuesto.totalImpuestos),
                          }
                        })
                        return (
                          <table className="table">
                            <tbody>
                              <tr>
                                <th
                                  className="th-totales"
                                  style={{ width: "50%" }}
                                >
                                  Subtotal
                                </th>
                                <td
                                  className="td-totales-right"
                                  style={{ width: "50%" }}
                                >
                                  {dineroDisabledMask(subtotal)}
                                </td>
                              </tr>
                            </tbody>
                            {arrayImpuestos.map((valueArray) => (
                              <tbody>
                                <tr key={valueArray.descripcionFactura}>
                                  <th className="th-totales">
                                    {valueArray.descripcionFactura}
                                  </th>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(
                                      valueArray.totalImpuestos
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            <tbody>
                              <tr>
                                <th className="th-totales">Total</th>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(total)}
                                </td>
                              </tr>
                            </tbody>
                            {arrayRetenciones.map((value) => (
                              <tbody>
                                <tr>
                                  <th className="th-totales">
                                    {value.descripcionFactura}
                                  </th>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(value.totalRetenciones)}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            <tbody>
                              <tr>
                                <th className="th-totales">Total a pagar</th>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(total - cuentasProveedor)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      }}
                    </FormItem>
                  </Col>
                </Row>
              </BoxItem>
            </Form>
          </Spin>
        </div>
        <ElegirTipoDeProveedor
          visible={this.state.modalElegirTipoDeProveedor}
          id={this.state.modalElegirTipoDeProveedorId}
          nombre={this.state.modalElegirTipoDeProveedorNombre}
          cerrarElegirTipoDeProveedor={this.cerrarElegirTipoDeProveedor.bind(this)}
        />
        <MandarCorreoOrdenDeCompra
          visible={this.state.modalCorreo}  
          cerrarMandarCorreoOrdenDeCompra={this.cerrarModalCorreo.bind(this)}  
          id={this.state.modalCorreoId} 
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarOrdenDeCompra);