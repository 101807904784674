import {
  message,
  PageHeader,
  Spin,
  Select,
  Form as FormInitial,
  Row,
  Col,
  Space,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  SelectItem,
  InputNumberItem,
  dineroDisabledMask,
  round2Decimals,
  ButtonItem,
  round6Decimals,
  HeaderItem,
  BoxItem,
  DatePickerItem,
} from "../../../../Components/Items";
import { BookOutlined } from "@ant-design/icons";
import FacturasOrdenDeCompra from "../FacturasOrdenDeCompra/FacturasOrdenDeCompra";
import MandarCorreoOrdenDeCompra from "../MandarCorreoOrdenDeCompra/MandarCorreoOrdenDeCompra";
import moment from "moment";

const { Option } = Select;

class VerOrdenDeCompra extends Component {
  formRef = React.createRef();
  state = {
    modalCorreo: false,
    loading: false,
    objetoProveedores: null,
    todasProveedores: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoInsumos: (
      <Option disabled value={0} key={0}>
        Seleccione un proveedor primero
      </Option>
    ),
    todasInsumos: [],
    impuestos: [],
    total: 0,
    subtotal: 0,
    arrayImpuestos: [],
    arrayRetenciones: [],
    totalPagar: 0,
    estado: 0,
    totalPagado: 0,
    numOrden: 0,
    estadoFactura: 0,
    modalFacturas: false,
    modalElegirTipoDeProveedor: false,
    modalElegirTipoDeProveedorId: null,
    modalElegirTipoDeProveedorNombre: null,
    id: this.props.location.state.id,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

  abrirModalCorreo() {
    this.setState({ modalCorreo: true });
  }

  cerrarModalCorreo() {
    this.setState({ modalCorreo: false });
  }

  async consultarTodo() {
    this.setState({ loading: true });
    const responseProveedores = axios
      .post(
        "proveedores/selectProveedores",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )


    const responseProyectos = axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )

    const responseMonedas = axios
      .post(
        "monedas/selectMonedas",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )

    const responses = await Promise.all([
      responseProveedores,
      responseProyectos,
      responseMonedas
    ])

    let newState = { loading: false }

    if (responses[0].data.status === 404) {
    } else {
      newState.todasProveedores = responses[0].data
      newState.objetoProveedores = responses[0].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.stringBuscar}
          </Option>
        );
      })
    }

    if (responses[1].data.status === 404) {
    } else {
      newState.todasProyectos = responses[1].data
      newState.objetoProyectos = responses[1].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre}
          </Option>
        );
      })
    }

    if (responses[2].data.status === 404) {
    } else {
      newState.todasMonedas = responses[2].data
      newState.objetoMonedas = responses[2].data.map((value) => {
        return (
          <Option value={value._id} key={value._id}>
            {value.nombre + " - " + value.abreviacion}
          </Option>
        );
      })
    }

    await this.promisedSetState(newState)

    let proveedorId

    await axios
      .post(
        "ordenesdecompra/datosOrden",
        { empresaId: this.props.empresaId, ordenId: this.state.id },
        { headers: this.props.headersToken }
      )
      .then(async (res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion de la orden de compra");
        } else {
          await this.formRef.current.setFieldsValue({
            ...res.data,
            fechaEntrega: moment(res.data.fechaEntrega),
          });
          await this.setState({
            totalPagado: res.data.totalPagado,
            estado: res.data.estado,
            estadoFactura: res.data.estadoFactura,
            total: res.data.total,
            subtotal: res.data.subtotal,
            arrayImpuestos: res.data.arrayImpuestos,
            totalPagar: res.data.totalPagar,
            arrayRetenciones: res.data.arrayRetenciones,
            numOrden: res.data.numero,
          });
          res.data.insumos.map((valueInsumo, keyInsumo) => {
            return this.onChangePrecioCantidadDescuento(null, keyInsumo)
          })
          proveedorId = res.data.proveedorId
          let newState = { total: res.data.total, totalPagar: res.data.totalPagar, totalPagado: res.data.totalPagado, subtotal: res.data.subtotal, arrayImpuestos: res.data.arrayImpuestos, arrayRetenciones: res.data.arrayRetenciones }

          this.setState(newState)
        }
      });

    await axios
      .post(
        "insumos/selectInsumosCompraYRelacionesProveedor",
        { empresaId: this.props.empresaId, proveedorId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });

  }
  atras() {
    this.props.history.goBack();
  }

  async onChangePrecioCantidadDescuento(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proveedorId = await this.formRef.current.getFieldValue("proveedorId");
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === arrayValue[key]?.insumoId
    );
    if (!insumo) return
    const precioUnitario = arrayValue[key].precioUnitario

    let totalImpuestosPrecioSinImpuestos = 0
    insumo.impuestos.map(valueImpuesto => {
      let totalImpuesto
      if (valueImpuesto.tasaCuota === 1) {
        totalImpuesto = (precioUnitario * (valueImpuesto.monto / 100))
      } else if (valueImpuesto.tasaCuota === 2) {
        totalImpuesto = (precioUnitario + totalImpuestosPrecioSinImpuestos) * (valueImpuesto.monto / 100)
      } else if (valueImpuesto.tasaCuota === 3) {
        totalImpuesto = valueImpuesto.monto
      } else if (valueImpuesto.tasaCuota === 4) {
        let cantidad = arrayValue[key].cantidad
        if (cantidad) {
          totalImpuesto = (arrayValue[key].impuestos || 0) / arrayValue[key].cantidad
        }
      } else if (valueImpuesto.tasaCuota === 5) {
        totalImpuesto = 0
      }
      totalImpuestosPrecioSinImpuestos += totalImpuesto
      return null
    })
    arrayValue[key].precioUnitarioConImpuestos = round2Decimals(precioUnitario + totalImpuestosPrecioSinImpuestos)
    const cantidad = arrayValue[key].cantidad
    const descuento = arrayValue[key].descuento || 0

    if (precioUnitario && cantidad && insumo) {
      //1
      const subtotal = round2Decimals(cantidad * precioUnitario);
      //2
      const totalDescuento = round2Decimals(subtotal * (descuento / 100))
      //3
      const totalEntrada = round2Decimals(subtotal - totalDescuento)
      arrayValue[key].subtotal = totalEntrada;

      let totalImpuestos = 0;
      let arrayImpuestos = insumo.impuestos.map(
        //4
        (valueImpuesto) => {
          let totalImpuesto;
          if (valueImpuesto.tasaCuota === 1) {
            totalImpuesto = round6Decimals(totalEntrada * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 2) {
            totalImpuesto =
              round6Decimals((totalEntrada + totalImpuestos) * (valueImpuesto.monto / 100));
          } else if (valueImpuesto.tasaCuota === 3) {
            totalImpuesto = round6Decimals(cantidad * valueImpuesto.monto);
          } else if (valueImpuesto.tasaCuota === 4) {
            totalImpuesto = round6Decimals(arrayValue[key].impuestos)
          } else if (valueImpuesto.tasaCuota === 5) {
            totalImpuesto = 0
          }
          totalImpuestos += totalImpuesto;
          return {
            descripcionFactura: valueImpuesto.descripcionFactura,
            totalImpuestos: totalImpuesto,
          };
        }
      );
      arrayValue[key].impuestos = totalImpuestos;
      arrayValue[key].arrayImpuestos = arrayImpuestos;
      //5
      const total = round2Decimals(arrayValue[key].subtotal + totalImpuestos)
      arrayValue[key].total = total;

      if (proveedorId) {
        //Consultar proveedor

        const proveedor = this.state.todasProveedores.find(
          (valueP) => valueP._id === proveedorId
        );
        //6
        let totalRetenciones = 0;
        let arrayRetenciones = []

        proveedor.retenciones.map((valueRetencion) => {
          //7
          let totalRetencion;
          if (valueRetencion.tasaCuota === 1) {
            totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
          } else if (valueRetencion.tasaCuota === 2) {
            totalRetencion =
              round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
          }
          totalRetenciones += totalRetencion;
          arrayRetenciones.push({
            descripcionFactura: valueRetencion.descripcionFactura,
            totalRetenciones: totalRetencion,
          });
          return null
        })

        insumo.retenciones.map((valueRetencion) => {
          if ((valueRetencion.aplicaCompra === 1 && proveedor.persona === false) || (valueRetencion.aplicaCompra === 2 && proveedor.persona === true) || valueRetencion.aplicaCompra === 3) {
            //7
            let totalRetencion;
            if (valueRetencion.tasaCuota === 1) {
              totalRetencion = round6Decimals(totalEntrada * (valueRetencion.monto / 100));
            } else if (valueRetencion.tasaCuota === 2) {
              totalRetencion =
                round6Decimals((totalEntrada + totalRetenciones) * (valueRetencion.monto / 100));
            }
            totalRetenciones += totalRetencion;
            arrayRetenciones.push({
              descripcionFactura: valueRetencion.descripcionFactura,
              totalRetenciones: totalRetencion,
            });
          }
          return null
        })
        arrayValue[key].retenciones = totalRetenciones;
        arrayValue[key].arrayRetenciones = arrayRetenciones;
      }
    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }

  cerrarFacturasOrdenDeCompra() {
    this.setState({ modalFacturas: false })
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Ver Orden de Compra"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="verOrdenDeCompra"
              layout
              bottom={
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Space>
                    {this.state.estadoFactura !== 0 && this.state.estado !== 0 &&
                      <ButtonItem
                        icon={<BookOutlined />}
                        blanco={true}
                        onClick={() => this.setState({ modalFacturas: true })}
                      >
                        Facturas
                      </ButtonItem>
                    }
                    <ButtonItem
                      type="primary"
                      onClick={this.abrirModalCorreo.bind(this)} // Abrir el modal
                    >
                      Mandar Correo
                    </ButtonItem>


                    <ButtonItem
                      type="primary"
                      danger
                      onClick={this.atras.bind(this)}
                    >
                      Regresar
                    </ButtonItem>
                  </Space>
                </div>

              }
            >
              <Row>
                <Col span={18}>
                  <HeaderItem>DATOS GENERALES</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={12}>
                        <FormItem label="Proyecto" name="proyectoId" margin required>
                          <SelectItem disabled placeholder="Proyecto">
                            {this.state.objetoProyectos}
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            let proyectoId = getFieldValue("proyectoId");
                            if (!proyectoId)
                              return <p>Seleccione un proyecto primero</p>;
                            let proyecto = this.state.todasProyectos.find(
                              (value) => value._id === proyectoId
                            );
                            return (
                              <>
                                <Row>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Nombre: {proyecto.nombre}
                                    </h1>
                                    <p>Descripcion: {proyecto.descripcion}</p>
                                  </Col>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>Dirección de envio</h1>
                                    {proyecto.calle ? (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Calle: {proyecto.calle}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Numero: {proyecto.numeroCalle}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p style={{ margin: 0 }}>
                                          Marca: {proyecto.marca}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Modelo: {proyecto.modelo}
                                        </p>
                                        <p style={{ margin: 0 }}>
                                          Placas: {proyecto.placas}
                                        </p>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <FormItem
                          label="Proveedor"
                          name="proveedorId"
                          margin
                          required
                        >
                          <SelectItem disabled placeholder="Proveedor">
                            {this.state.objetoProveedores}
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            let proveedorId = getFieldValue("proveedorId");
                            if (!proveedorId)
                              return <p>Seleccione un proveedor primero</p>;
                            let proveedor = this.state.todasProveedores.find(
                              (value) => value._id === proveedorId
                            );
                            return (
                              <>
                                <Row>
                                  <Col span={12}>
                                    <h1 style={{ margin: 0 }}>
                                      Nombre:{" "}
                                      {proveedor.nombre
                                        ? proveedor.nombre +
                                        " " +
                                        proveedor.apellidoPaterno +
                                        " " +
                                        proveedor.apellidoMaterno
                                        : proveedor.razonSocial}
                                    </h1>
                                    <p style={{ margin: 0 }}>Rfc: {proveedor.rfc}</p>
                                    <p style={{ margin: 0 }}>
                                      Correo: {proveedor.correo}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Telefono: {proveedor.telefono}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Celular: {proveedor.celular}
                                    </p>
                                  </Col>
                                  <Col span={12}>
                                    <p style={{ margin: 0 }}>
                                      Contacto: {proveedor.nombreContacto}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Tel. Contacto: {proveedor.telefonoContacto}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Correo Contacto: {proveedor.correoContacto}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      Dias de crédito:{" "}
                                      {proveedor.diasCredito}
                                    </p>
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <FormItem
                          label="Fecha Entrega"
                          name="fechaEntrega"
                          margin
                        >
                          <DatePickerItem
                            placeholder="Fecha de Entrega"
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </BoxItem>
                </Col>
                <Col span={6}>
                  <HeaderItem>MONEDA</HeaderItem>
                  <BoxItem>
                    <FormItem
                      required
                      margin
                      name="monedaId"
                      label="Moneda"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValue='60c7e4396465ad440c41c15a'
                    >
                      <SelectItem disabled onClick={() => message.info('Por el momento nomas se pueden realizar ordenes de compra con moneda MXN')} onChange={(value) => {
                        if (value === '60c7e4396465ad440c41c15a') {
                          this.formRef.current.setFieldsValue({ tipoDeCambio: 1 })
                        }
                      }} placeholder="Moneda">
                        {this.state.objetoMonedas}
                      </SelectItem>
                    </FormItem>
                    <FormItem
                      required
                      number
                      margin
                      label="T. Cambio"
                      name="tipoDeCambio"
                      type="number"
                      initialValue={1}
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <InputNumberItem
                        disabled
                        dinero
                        placeholder="T. Cambio"
                      />
                    </FormItem>
                  </BoxItem>
                </Col>
              </Row>
              <HeaderItem>INSUMOS</HeaderItem>
              <BoxItem>
                <FormInitial.List name="insumos">
                  {(fields, { add, remove }) => (
                    <>
                      <table className="table">
                        {fields.length > 0 ? (
                          <thead>
                            <tr>
                              <th className="th-border" style={{ width: "26%" }}>
                                Insumo
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Precio Unitario
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Precio Unitario con Impuestos
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Cantidad
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Descuento
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Subtotal
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Impuestos
                              </th>
                              <th className="th-border" style={{ width: "10%" }}>
                                Total
                              </th>
                              <th
                                className="th-border-sin-right"
                                style={{ width: "4%" }}
                              ></th>
                            </tr>
                          </thead>
                        ) : null}
                        {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                          <tbody key={arrayKey}>
                            <tr>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "insumoId"]}
                                  required
                                  noStyle
                                >
                                  <SelectItem
                                    disabled
                                    sinBorde
                                    autoFocus
                                    style={{ width: "100%" }}
                                    placeholder="Insumo"
                                    onChange={(value) =>
                                      this.onChangeInsumo(value, arrayKey)
                                    }
                                  >
                                    {this.state.objetoInsumos}
                                  </SelectItem>
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "precioUnitario"]}
                                  noStyle
                                  required
                                >
                                  <InputNumberItem
                                    disabled
                                    sinBorde
                                    placeholder="Precio Unitario"
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                    dinero
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  name={[name, "precioUnitarioConImpuestos"]}
                                  noStyle
                                  required
                                  shouldUpdate
                                >
                                  <InputNumberItem
                                    sinBorde
                                    disabled
                                    placeholder="Precio Unitario con Impuestos"
                                    onChange={(value) =>
                                      this.onChangePrecioImpuestos(value, arrayKey)
                                    }
                                    dineroDisabled
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "cantidad"]}
                                  required
                                  number
                                  noStyle
                                >
                                  <InputNumberItem
                                    disabled
                                    sinBorde
                                    placeholder="Cantidad"
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "descuento"]}
                                  required
                                  numberCero
                                  noStyle
                                  initialValue={0}
                                >
                                  <InputNumberItem
                                    disabled
                                    sinBorde
                                    placeholder="Descuento"
                                    porcentaje
                                    max={100}
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "subtotal"]}
                                  required
                                  numberCero
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    disabled
                                    placeholder="Subtotal"
                                    dineroDisabled
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  name={[name, "impuestos"]}
                                  required
                                  numberCero
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    disabled
                                    dineroDisabled
                                    placeholder="Impuestos"
                                    onChange={(value) =>
                                      this.onChangePrecioCantidadDescuento(value, arrayKey)
                                    }
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "total"]}
                                  required
                                  numberCero
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    dineroDisabled
                                    disabled
                                    placeholder="Total"
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border-sin-right">
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </>
                  )}
                </FormInitial.List>
                <Row>
                  <Col span={16}></Col>
                  <Col span={8}>
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        return (
                          <table className="table">
                            <tbody>
                              <tr>
                                <th
                                  className="th-totales"
                                  style={{ width: "50%" }}
                                >
                                  Subtotal
                                </th>
                                <td
                                  className="td-totales-right"
                                  style={{ width: "50%" }}
                                >
                                  {dineroDisabledMask(this.state.subtotal)}
                                </td>
                              </tr>
                            </tbody>
                            {this.state.arrayImpuestos.map((valueArray) => (
                              <tbody>
                                <tr key={valueArray.descripcionFactura}>
                                  <th className="th-totales">
                                    {valueArray.descripcionFactura}
                                  </th>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(
                                      valueArray.totalImpuestos
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            <tbody>
                              <tr>
                                <th className="th-totales">Total</th>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(this.state.total)}
                                </td>
                              </tr>
                            </tbody>
                            {this.state.arrayRetenciones.map((value) => (
                              <tbody>
                                <tr>
                                  <th className="th-totales">
                                    {value.descripcionFactura}
                                  </th>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(value.totalRetenciones)}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            <tbody>
                              <tr>
                                <th className="th-totales">Total a pagar</th>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(this.state.totalPagar)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      }}
                    </FormItem>
                  </Col>
                </Row>
              </BoxItem>
            </Form>
          </Spin>
        </div>
        <FacturasOrdenDeCompra
          history={this.props.history}
          visible={this.state.modalFacturas}
          id={this.state.id}
          cerrarFacturasOrdenDeCompra={this.cerrarFacturasOrdenDeCompra.bind(this)}
        />
        <MandarCorreoOrdenDeCompra
          visible={this.state.modalCorreo}
          cerrarMandarCorreoOrdenDeCompra={this.cerrarModalCorreo.bind(this)}
          id={this.state.id}
        />

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(VerOrdenDeCompra);
