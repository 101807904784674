import React, { Component } from 'react';
import './Styles.css'; // Importa el archivo CSS
import { AutoComplete } from 'antd';

class AutoCompleteItem extends Component {
    render() {
        const { style, sinBorde, width, ...rest } = this.props;

        // Definir la clase CSS que se aplicará
        const className = `input-item ${sinBorde ? 'sin-borde' : ''}`;

        return (
            <AutoComplete 
                {...rest} 
                className={sinBorde ? undefined : className} 
                style={{ width: width || '100%', ...style}} 
                bordered={false} 
            />
        );
    }
}

export default AutoCompleteItem;